// Log JavaScript errors to the server.

function getCookie (name) {
    const pairs = document.cookie.split('; ')
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=')
        if (pair[0] == name || pair[0] == escape(name)) {
            return unescape(pair[1])
        }
    }
    return null // if no match return null
}

function logErrorToServer (event) {
    // Send the error to the server.
    const authenticityToken = getCookie('authenticity_token')
    const headers = {}
    if (authenticityToken) {
        headers['X-Authenticity-Token'] = authenticityToken
    }

    const errorData = {
        domReady: domReady,
        loadEvent: loadEvent,
        timeStamp: event.timeStamp,
        url: document.location.href,
        userAgent: window.navigator.userAgent,
        cookies: document.cookie,
        colno: '',
        filename: '',
        lineno: '',
        message: event.message || 'Unknown error',
        stack: ''
    }

    if (event instanceof ErrorEvent) {
        errorData.colno = event.colno
        errorData.filename = event.filename
        errorData.lineno = event.lineno
        if (event.error) {
            if (event.error.message) {
                errorData.message = event.error.message
            }
            if (event.error.stack) {
                errorData.stack = event.error.stack
            }
        }
    }

    if (event.target && event.target.tagName == 'SCRIPT') {
        // Script error logging to support non-standard browsers (Safari < v16).
        errorData.filename = event.target.src || 'inline script'
        errorData.message = 'Failed to load script'
    }

    const formData = new FormData()
    formData.append('error', JSON.stringify({ error: errorData }))

    fetch('/tcl/log-js-error', {
        method: 'POST',
        body: formData,
        headers: headers,
        credentials: 'include'
    })
}

window.logErrorToServer = logErrorToServer

let domReady = false
let loadEvent = false

document.addEventListener('DOMContentLoaded', function () {
    domReady = true
})

window.addEventListener('load', function () {
    loadEvent = true
})

window.addEventListener('error', logErrorToServer)
